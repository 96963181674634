:root {
  font-family: "Lora", serif;
  font-weight: 400;
  --primary-pink: #dd77bb;
  --primary-teal: #77d4c5;
  --neutral-100: #f9f9f0;
  --neutral-300: rgba(100, 100, 100, 0.4);
  --neutral-350: rgba(100, 100, 100, 0.7);
  --neutral-750: rgba(100, 100, 100, 0.9);
  --neutral-900: rgb(40, 40, 40);

  --ease-in-out-3: cubic-bezier(0.5, 0, 0.5, 1);
  --gradient: linear-gradient(
    140deg,
    var(--primary-pink) 25%,
    var(--primary-teal) 75%
  );
  --bw-gradient: linear-gradient(
    130deg,
    rgba(65, 65, 65, 0.64) 15%,
    var(--neutral-350) 75%
  );
  --button-color: var(--neutral-350);
  --nav-height: 7rem;
  object-fit: cover;
  scroll-behavior: smooth;
  scroll-padding-top: calc(var(--nav-height) - 1px);
  // overflow: hidden;
}

body {
  background-color: var(--neutral-900);
  color: var(--neutral-100);
  // overflow: hidden;
  width: 100%;
  font-size: 1rem;
  * {
    // border: #bada55 solid 1px;
  }
  @media (min-width: 1200px) {
    p,
    li {
      font-size: 1.2rem;
    }
  }
}

/* NAV */
.nav-header {
  background-color: var(--neutral-900);
  width: 100%;
  font-size: 1rem;
  overflow: hidden;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  padding: 2rem 5vw;
  position: fixed;
  font-weight: 600;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  @media (min-width: 800px) {
    flex-wrap: nowrap;
  }
  @media (min-width: 1000px) {
    padding-inline: 15vw;
    font-size: 1.25rem;
  }
}
.page-title {
  display: grid;
  height: 100%;
  &--logo {
    max-height: 4rem;
    min-width: 100px;
    grid-column: 1/2;
    grid-row: 1/3;
    align-self: center;
  }
  p {
    grid-column: 2/3;
    margin: 0;
    padding: 0;
    padding-left: 0.5rem;
    line-height: 1.5rem;
    font-size: 1.25rem;
    font-weight: 300;
  }
  p:nth-of-type(1) {
    align-self: flex-end;
  }
  &--teal {
    color: var(--primary-teal);
  }
  &--pink {
    color: var(--primary-pink);
  }
  @media (max-width: 800px) {
    margin-right: 30vw;
    p {
      font-size: 1.2rem;
      z-index: 50;
    }
  }
}
nav {
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  position: relative;

  @media (max-width: 800px) {
    position: fixed;
    right: 0;
    top: 0;
    height: max-content;
    flex-direction: column;
    gap: 0.25rem;
    align-items: flex-end;
    background-color: var(--neutral-900);
    padding: 1rem;
    padding-top: var(--nav-height);
    border-radius: 0 0 0 0.5rem;
    font-size: 1rem;
    box-shadow: -2px 2px 7px -4px var(--neutral-900);
    transform: translateX(100%);

    transition: transform 150ms ease-out;
  }
  &[data-visible="true"] {
    transform: translateX(0%);
  }
}

.menu-toggle {
  position: absolute;
  top: calc(var(--nav-height) / 3);
  right: 1rem;
  height: 2rem;
  width: 2rem;
  background: none;
  border: none;
  z-index: 50;
  cursor: pointer;
  @media (min-width: 800px) {
    display: none;
  }
}

.nav-link {
  color: var(--neutral-100);
  text-decoration: none;
  text-transform: uppercase;
  transition: color 200ms ease-in-out;
  &:hover,
  &:focus {
    color: var(--primary-teal);
    cursor: pointer;
    // animation: shake_y 800ms normal forwards;
  }
  img {
    margin-inline: auto;
    height: 1.5rem;
    width: 1.2rem;
    max-width: none;
  }
}

.button,
.nav-link {
  background-color: rgba(100, 100, 100, 0);
  border: none;
  color: var(--neutral-100);
  padding: 0.4rem;
  border-radius: 0.2rem;
  background-size: 200%;
}
.button:hover {
  cursor: pointer;
  background-image: var(--gradient);
  background-position: left;
  animation: shake_y 300ms normal forwards,
    gradient-animation 2000ms linear alternate infinite;
}

/* HERO */
.hero-img {
  width: 100%;
  height: 90%;
  object-fit: contain;
  overflow: hidden;
}

/* DIVIDERS */
.divider {
  background-color: var(--neutral-100);
  color: var(--neutral-750);
  display: grid;
  justify-items: center;
  padding-block: 3rem;
  padding-inline: 1rem;
  text-align: justify;
  * {
    max-width: 70ch;
    font-weight: 500;
  }
  h2 {
    width: 100%;
    font-size: 2rem;
    text-align: center;
    font-weight: 600;
    color: var(--primary-teal);
    margin-bottom: 1rem;
  }
  p {
    font-family: "Montserrat", sans-serif;
    margin-inline: max(5vw, 1rem);
    max-width: 60ch;
  }
  @media (min-width: 800px) {
    h2 {
      font-size: 2rem;
    }
  }
}
/* ABOUT BLURB */
.about-blurb {
  h2 {
    line-height: 2.5rem;
  }
}

/* GALLERY */
.gallery-1 {
  grid-area: gallery1;
  object-position: top;
}
.gallery-2 {
  grid-area: insta1;
  transform-origin: top right;
  @media (min-width: 900px) {
    transform-origin: top left;
  }
  @media (min-width: 1200px) {
    transform-origin: top;
  }
}
.gallery-3 {
  grid-area: insta2;
  transform-origin: left;
  @media (min-width: 900px) {
    transform-origin: top right;
  }

  @media (min-width: 1200px) {
    transform-origin: top;
  }
}
.gallery-4 {
  grid-area: gallery2;
}
.gallery-5 {
  grid-area: gallery3;
}
.gallery-6 {
  grid-area: gallery4;
}
.gallery-7 {
  grid-area: insta3;
  transform-origin: bottom right;
  @media (min-width: 900px) {
    transform-origin: bottom left;
  }

  @media (min-width: 1200px) {
    transform-origin: bottom;
  }
}

.gallery {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 33vw repeat(3, 15vw) 33vw;
  align-items: stretch;
  padding: 0.3rem;
  gap: 0.3rem;
  grid-template-areas:
    "gallery1 gallery1 insta1"
    "insta2 gallery2 gallery2"
    "insta2 gallery2 gallery2"
    "gallery4 gallery2 gallery2"
    "gallery4 gallery3 insta3";

  @media (min-width: 900px) {
    width: 100%;
    grid-template-columns: 1fr 1fr 1.5fr 1fr;
    grid-template-rows: repeat(3, 30vw);
    grid-template-areas:
      "insta1 gallery1 gallery1 insta2"
      "gallery3 gallery3  gallery2 gallery2"
      "insta3 gallery4 gallery2 gallery2";
  }

  @media (min-width: 1200px) {
    margin-inline: auto;
    margin-block: 1rem;
    width: min(100%, 1200px);
    grid-template-rows: repeat(3, clamp(18vw, 30vh, 25vw));
  }
}

.gallery > *,
.insta-link > * {
  border-radius: 0.25rem;
  object-fit: cover;
  width: 100%;
  overflow: hidden;
}

.gallery-2,
.gallery-3,
.gallery-7 {
  img {
    object-position: top left;
  }
  &:hover {
    transform: scale(1.2);
  }
}

.insta-link {
  position: relative;
  object-fit: cover;
  z-index: 5;
  border-bottom: 3px var(--primary-pink) inset;
  transition: all 250ms var(--ease-in-out-3);
  &:hover {
    border: 3px var(--primary-pink) solid;
    border-radius: 0.4rem;

    &:after {
      opacity: 1;
    }
  }
  &:after {
    content: " ";
    display: block;
    position: absolute;
    background-image: url(./assets/imgs/instagram-gallery.svg);
    background-color: var(--neutral-350);
    background-repeat: no-repeat;
    border-top-left-radius: 0.5rem;
    background-size: 75%;
    background-position: center;
    /* background-color: var(--primary-pink); */
    width: 1.5rem;
    height: 1.5rem;
    bottom: 0;
    right: 0;
    opacity: 1;
    transition: opacity 200ms;
    @media (min-width: 900px) {
      opacity: 0;
      background-color: unset;
      width: 2rem;
      height: 2rem;
      bottom: 0;
      right: 0;
    }
  }
}

.insta {
  object-fit: cover;
  height: 100%;
  aspect-ratio: 1;
}

/* TESTIMONIALS */
.testimonials-slider {
  height: 70vh;
  margin: 0 auto;
  position: relative;
  max-width: 100vw;
  /* IN THE END */
  overflow: hidden;

  @media (min-width: 900px) {
    height: 22rem;
  }
}
.testimonial {
  width: 80%;
  position: relative;
  &-author {
    text-align: right;
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
  }
  &:before {
    content: "\201C";
    position: absolute;
    top: -5rem;
    left: -2rem;
    line-height: 1;
    font-size: 15rem;
    font-family: inherit;
    color: var(--primary-teal);
    z-index: -1;
    @media (min-width: 900px) {
      top: -4rem;
      left: -2rem;
    }
  }
  @media (min-width: 1200px) {
    p {
      margin-inline: 0;
    }
  }
}

.slide {
  position: absolute;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 1s;
  @media (min-width: 900px) {
    width: 150%;
    max-width: 80ch;
  }
}
.slider__btn {
  position: absolute;
  top: 50%;
  border: none;
  background: none;
  color: var(--neutral-750);
  height: 4.5rem;
  width: 4.5rem;
  cursor: pointer;
  background-position: center;
  background-repeat: no-repeat;
  display: none;
  @media (min-width: 650px) {
    display: block;
  }
}
.slider__btn--left {
  background-image: url(./assets/imgs/angle-left-solid.svg);
  left: 10%;
  transform: translate(-50%, -50%);
}

.slider__btn--right {
  background-image: url(./assets/imgs/angle-right-solid.svg);
  background-repeat: no-repeat;
  right: 10%;
  transform: translate(50%, -50%);
}
.dots {
  position: absolute;
  bottom: 7%;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  &__dot {
    border: none;
    background-color: var(--neutral-300);
    opacity: 0.7;
    height: 1rem;
    width: 1rem;
    border-radius: 50%;
    margin-right: 1.75rem;
    cursor: pointer;
    transition: all 0.5s;
    &:hover {
      cursor: pointer;
    }
    &:last-child {
      margin: 0;
    }
    &--active {
      /* background-color: #fff; */
      background-color: var(--neutral-750);
      opacity: 1;
    }
  }
}

/* CONTACT */
.contact {
  background-image: url("./assets/imgs/contact-form.jpg");
  background-size: cover;
  display: grid;
  justify-items: center;
  padding-block: 2rem;
  &-form {
    display: grid;
    grid-template-columns: 1fr;
    gap: 4px;
    justify-items: center;
    * {
      width: 100%;
      font-family: "Montserrat", sans-serif;
    }
    label > input,
    textarea {
      display: block;
      transition: outline 50ms;
      resize: none;
    }
    label > input:focus,
    textarea:focus {
      outline: var(--primary-teal) solid 2px;
      display: block;
    }
    label > input:user-invalid,
    textarea:user-invalid {
      outline: var(--primary-pink) solid 2px;
      display: block;
    }
  }
}

.contact-btn {
  background-image: var(--bw-gradient);
  background-position: left;
  animation: gradient-animation 2000ms linear alternate infinite;
  position: relative;
}

/* CLIENTS */
.clients {
  p {
    font-weight: 600;
    text-align: center;
  }
}
.clients-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 70ch;
  * {
    list-style: none;
  }
  *:not(:last-child):after {
    content: "//";
    display: inline-block;
    margin-inline: 5px;
  }
}

/* ABOUT */
.services {
  grid-area: services;
}
.process {
  grid-area: process;
}
.about-alyssa {
  grid-area: about-alyssa;
}
.about-photo {
  &1 {
    grid-area: photo1;
  }
  &2 {
    grid-area: photo2;
  }
  &3 {
    grid-area: photo3;
  }
}
[class*="about-photo"] {
  height: 100%;
}

.about {
  display: grid;
  background-color: black;
  width: 100%;
  grid-template-rows: repeat(6, min-content);
  grid-template-columns: 1fr;
  align-items: stretch;
  grid-template-areas:
    "services"
    "photo1"
    "process"
    "photo2"
    "about-alyssa"
    "photo3";
  padding-block: 2rem;

  &-card {
    align-self: center;
    text-align: justify;
    object-fit: cover;
    height: max-content;
    h2 {
      text-align: center;
      // margin-top: 2rem;
    }
    p {
      font-family: "Montserrat", sans-serif;
      padding: 2rem;
    }
    img {
      height: 100%;
      object-fit: cover;
    }
  }
  @media screen and (min-width: 700px) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(3, 0.8fr);
    grid-template-areas:
      "services photo1"
      "photo2 process"
      "about-alyssa photo3";
  }
  @media (min-width: 1200px) {
    margin-inline: auto;
    margin-block: 2rem;
    width: min(100%, 1200px);
    border-radius: 1rem;
    overflow: hidden;
    font-size: 1.25rem;
  }
}
.footer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  p {
    margin-inline: 0.5rem;
  }
  a {
    color: var(--neutral-900);
    transition: color 200ms;
    &:hover {
      color: var(--neutral-300);
    }
  }
}
/* ANIMATIONS */
@keyframes gradient-animation {
  100% {
    background-position: right;
  }
}

@keyframes shake_y {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5%);
  }
  80% {
    transform: translateY(1%);
  }
  100% {
    transform: translateY(0);
  }
}

/* MEDIA QUERIES */
@media (min-width: 650px) {
  // .about {
  //   width: 100%;
  //   display: grid;
  //   grid-template-columns: 1fr 1fr;
  //   grid-template-rows: repeat(3, 0.8fr);
  //   grid-template-areas:
  //     "services photo1"
  //     "photo2 process"
  //     "about-alyssa photo3";
  // }
  .contact-form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    justify-items: center;
  }
  .contact-btn {
    grid-column: 1/3;
  }
}
