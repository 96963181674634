:root {
  --primary-pink: #d7b;
  --primary-teal: #77d4c5;
  --neutral-100: #f9f9f0;
  --neutral-300: #64646466;
  --neutral-350: #646464b3;
  --neutral-750: #646464e6;
  --neutral-900: #282828;
  --ease-in-out-3: cubic-bezier(.5, 0, .5, 1);
  --gradient: linear-gradient( 140deg, var(--primary-pink) 25%, var(--primary-teal) 75% );
  --bw-gradient: linear-gradient( 130deg, #414141a3 15%, var(--neutral-350) 75% );
  --button-color: var(--neutral-350);
  --nav-height: 7rem;
  object-fit: cover;
  scroll-behavior: smooth;
  scroll-padding-top: calc(var(--nav-height)  - 1px);
  font-family: Lora, serif;
  font-weight: 400;
}

body {
  background-color: var(--neutral-900);
  color: var(--neutral-100);
  width: 100%;
  font-size: 1rem;
}

@media (min-width: 1200px) {
  body p, body li {
    font-size: 1.2rem;
  }
}

.nav-header {
  background-color: var(--neutral-900);
  width: 100%;
  z-index: 10;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 5vw;
  font-size: 1rem;
  font-weight: 600;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  overflow: hidden;
}

@media (min-width: 800px) {
  .nav-header {
    flex-wrap: nowrap;
  }
}

@media (min-width: 1000px) {
  .nav-header {
    padding-inline: 15vw;
    font-size: 1.25rem;
  }
}

.page-title {
  height: 100%;
  display: grid;
}

.page-title--logo {
  max-height: 4rem;
  min-width: 100px;
  grid-area: 1 / 1 / 3 / 2;
  align-self: center;
}

.page-title p {
  padding: 0;
  grid-column: 2 / 3;
  margin: 0;
  padding-left: .5rem;
  font-size: 1.25rem;
  font-weight: 300;
  line-height: 1.5rem;
}

.page-title p:nth-of-type(1) {
  align-self: flex-end;
}

.page-title--teal {
  color: var(--primary-teal);
}

.page-title--pink {
  color: var(--primary-pink);
}

@media (max-width: 800px) {
  .page-title {
    margin-right: 30vw;
  }

  .page-title p {
    z-index: 50;
    font-size: 1.2rem;
  }
}

nav {
  height: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: .5rem;
  display: flex;
  position: relative;
}

@media (max-width: 800px) {
  nav {
    height: max-content;
    background-color: var(--neutral-900);
    padding: 1rem;
    padding-top: var(--nav-height);
    box-shadow: -2px 2px 7px -4px var(--neutral-900);
    border-radius: 0 0 0 .5rem;
    flex-direction: column;
    align-items: flex-end;
    gap: .25rem;
    font-size: 1rem;
    transition: transform .15s ease-out;
    position: fixed;
    top: 0;
    right: 0;
    transform: translateX(100%);
  }
}

nav[data-visible="true"] {
  transform: translateX(0%);
}

.menu-toggle {
  top: calc(var(--nav-height) / 3);
  height: 2rem;
  width: 2rem;
  z-index: 50;
  cursor: pointer;
  background: none;
  border: none;
  position: absolute;
  right: 1rem;
}

@media (min-width: 800px) {
  .menu-toggle {
    display: none;
  }
}

.nav-link {
  color: var(--neutral-100);
  text-transform: uppercase;
  text-decoration: none;
  transition: color .2s ease-in-out;
}

.nav-link:hover, .nav-link:focus {
  color: var(--primary-teal);
  cursor: pointer;
}

.nav-link img {
  height: 1.5rem;
  width: 1.2rem;
  max-width: none;
  margin-inline: auto;
}

.button, .nav-link {
  color: var(--neutral-100);
  background-color: #64646400;
  background-size: 200%;
  border: none;
  border-radius: .2rem;
  padding: .4rem;
}

.button:hover {
  cursor: pointer;
  background-image: var(--gradient);
  background-position: 0;
  animation: shake_y .3s forwards, gradient-animation 2s linear infinite alternate;
}

.hero-img {
  width: 100%;
  height: 90%;
  object-fit: contain;
  overflow: hidden;
}

.divider {
  background-color: var(--neutral-100);
  color: var(--neutral-750);
  text-align: justify;
  justify-items: center;
  padding-block: 3rem;
  padding-inline: 1rem;
  display: grid;
}

.divider * {
  max-width: 70ch;
  font-weight: 500;
}

.divider h2 {
  width: 100%;
  text-align: center;
  color: var(--primary-teal);
  margin-bottom: 1rem;
  font-size: 2rem;
  font-weight: 600;
}

.divider p {
  max-width: 60ch;
  margin-inline: max(5vw, 1rem);
  font-family: Montserrat, sans-serif;
}

@media (min-width: 800px) {
  .divider h2 {
    font-size: 2rem;
  }
}

.about-blurb h2 {
  line-height: 2.5rem;
}

.gallery-1 {
  object-position: top;
  grid-area: gallery1;
}

.gallery-2 {
  transform-origin: 100% 0;
  grid-area: insta1;
}

@media (min-width: 900px) {
  .gallery-2 {
    transform-origin: 0 0;
  }
}

@media (min-width: 1200px) {
  .gallery-2 {
    transform-origin: top;
  }
}

.gallery-3 {
  transform-origin: 0;
  grid-area: insta2;
}

@media (min-width: 900px) {
  .gallery-3 {
    transform-origin: 100% 0;
  }
}

@media (min-width: 1200px) {
  .gallery-3 {
    transform-origin: top;
  }
}

.gallery-4 {
  grid-area: gallery2;
}

.gallery-5 {
  grid-area: gallery3;
}

.gallery-6 {
  grid-area: gallery4;
}

.gallery-7 {
  transform-origin: 100% 100%;
  grid-area: insta3;
}

@media (min-width: 900px) {
  .gallery-7 {
    transform-origin: 0 100%;
  }
}

@media (min-width: 1200px) {
  .gallery-7 {
    transform-origin: bottom;
  }
}

.gallery {
  width: 100%;
  grid-template-rows: 33vw repeat(3, 15vw) 33vw;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-areas: "gallery1 gallery1 insta1"
                       "insta2 gallery2 gallery2"
                       "insta2 gallery2 gallery2"
                       "gallery4 gallery2 gallery2"
                       "gallery4 gallery3 insta3";
  align-items: stretch;
  gap: .3rem;
  padding: .3rem;
  display: grid;
}

@media (min-width: 900px) {
  .gallery {
    width: 100%;
    grid-template-rows: repeat(3, 30vw);
    grid-template-columns: 1fr 1fr 1.5fr 1fr;
    grid-template-areas: "insta1 gallery1 gallery1 insta2"
                         "gallery3 gallery3 gallery2 gallery2"
                         "insta3 gallery4 gallery2 gallery2";
  }
}

@media (min-width: 1200px) {
  .gallery {
    width: min(100%, 1200px);
    grid-template-rows: repeat(3, clamp(18vw, 30vh, 25vw));
    margin-block: 1rem;
    margin-inline: auto;
  }
}

.gallery > *, .insta-link > * {
  object-fit: cover;
  width: 100%;
  border-radius: .25rem;
  overflow: hidden;
}

.gallery-2 img, .gallery-3 img, .gallery-7 img {
  object-position: top left;
}

.gallery-2:hover, .gallery-3:hover, .gallery-7:hover {
  transform: scale(1.2);
}

.insta-link {
  object-fit: cover;
  z-index: 5;
  border-bottom: 3px var(--primary-pink) inset;
  transition: all 250ms var(--ease-in-out-3);
  position: relative;
}

.insta-link:hover {
  border: 3px var(--primary-pink) solid;
  border-radius: .4rem;
}

.insta-link:hover:after {
  opacity: 1;
}

.insta-link:after {
  content: " ";
  background-image: url("instagram-gallery.4d2297e0.svg");
  background-color: var(--neutral-350);
  width: 1.5rem;
  height: 1.5rem;
  opacity: 1;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 75%;
  border-top-left-radius: .5rem;
  transition: opacity .2s;
  display: block;
  position: absolute;
  bottom: 0;
  right: 0;
}

@media (min-width: 900px) {
  .insta-link:after {
    opacity: 0;
    background-color: unset;
    width: 2rem;
    height: 2rem;
    bottom: 0;
    right: 0;
  }
}

.insta {
  object-fit: cover;
  height: 100%;
  aspect-ratio: 1;
}

.testimonials-slider {
  height: 70vh;
  max-width: 100vw;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
}

@media (min-width: 900px) {
  .testimonials-slider {
    height: 22rem;
  }
}

.testimonial {
  width: 80%;
  position: relative;
}

.testimonial-author {
  text-align: right;
  font-family: Montserrat, sans-serif;
  font-weight: 700;
}

.testimonial:before {
  content: "“";
  color: var(--primary-teal);
  z-index: -1;
  font-family: inherit;
  font-size: 15rem;
  line-height: 1;
  position: absolute;
  top: -5rem;
  left: -2rem;
}

@media (min-width: 900px) {
  .testimonial:before {
    top: -4rem;
    left: -2rem;
  }
}

@media (min-width: 1200px) {
  .testimonial p {
    margin-inline: 0;
  }
}

.slide {
  height: 100%;
  justify-content: center;
  align-items: center;
  transition: transform 1s;
  display: flex;
  position: absolute;
}

@media (min-width: 900px) {
  .slide {
    width: 150%;
    max-width: 80ch;
  }
}

.slider__btn {
  color: var(--neutral-750);
  height: 4.5rem;
  width: 4.5rem;
  cursor: pointer;
  background: center no-repeat;
  border: none;
  display: none;
  position: absolute;
  top: 50%;
}

@media (min-width: 650px) {
  .slider__btn {
    display: block;
  }
}

.slider__btn--left {
  background-image: url("angle-left-solid.b53ee82a.svg");
  left: 10%;
  transform: translate(-50%, -50%);
}

.slider__btn--right {
  background-image: url("angle-right-solid.5b342635.svg");
  background-repeat: no-repeat;
  right: 10%;
  transform: translate(50%, -50%);
}

.dots {
  display: flex;
  position: absolute;
  bottom: 7%;
  left: 50%;
  transform: translateX(-50%);
}

.dots__dot {
  background-color: var(--neutral-300);
  opacity: .7;
  height: 1rem;
  width: 1rem;
  cursor: pointer;
  border: none;
  border-radius: 50%;
  margin-right: 1.75rem;
  transition: all .5s;
}

.dots__dot:hover {
  cursor: pointer;
}

.dots__dot:last-child {
  margin: 0;
}

.dots__dot--active {
  background-color: var(--neutral-750);
  opacity: 1;
}

.contact {
  background-image: url("contact-form.7126faa0.jpg");
  background-size: cover;
  justify-items: center;
  padding-block: 2rem;
  display: grid;
}

.contact-form {
  grid-template-columns: 1fr;
  justify-items: center;
  gap: 4px;
  display: grid;
}

.contact-form * {
  width: 100%;
  font-family: Montserrat, sans-serif;
}

.contact-form label > input, .contact-form textarea {
  resize: none;
  transition: outline 50ms;
  display: block;
}

.contact-form label > input:focus, .contact-form textarea:focus {
  outline: var(--primary-teal) solid 2px;
  display: block;
}

.contact-form label > input:user-invalid, .contact-form textarea:user-invalid {
  outline: var(--primary-pink) solid 2px;
  display: block;
}

.contact-btn {
  background-image: var(--bw-gradient);
  background-position: 0;
  animation: gradient-animation 2s linear infinite alternate;
  position: relative;
}

.clients p {
  text-align: center;
  font-weight: 600;
}

.clients-list {
  max-width: 70ch;
  flex-wrap: wrap;
  justify-content: center;
  display: flex;
}

.clients-list * {
  list-style: none;
}

.clients-list :not(:last-child):after {
  content: "//";
  margin-inline: 5px;
  display: inline-block;
}

.services {
  grid-area: services;
}

.process {
  grid-area: process;
}

.about-alyssa {
  grid-area: about-alyssa;
}

.about-photo1 {
  grid-area: photo1;
}

.about-photo2 {
  grid-area: photo2;
}

.about-photo3 {
  grid-area: photo3;
}

[class*="about-photo"] {
  height: 100%;
}

.about {
  width: 100%;
  background-color: #000;
  grid-template-rows: repeat(6, min-content);
  grid-template-columns: 1fr;
  grid-template-areas: "services"
                       "photo1"
                       "process"
                       "photo2"
                       "about-alyssa"
                       "photo3";
  align-items: stretch;
  padding-block: 2rem;
  display: grid;
}

.about-card {
  text-align: justify;
  object-fit: cover;
  height: max-content;
  align-self: center;
}

.about-card h2 {
  text-align: center;
}

.about-card p {
  padding: 2rem;
  font-family: Montserrat, sans-serif;
}

.about-card img {
  height: 100%;
  object-fit: cover;
}

@media screen and (min-width: 700px) {
  .about {
    grid-template-rows: repeat(3, .8fr);
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "services photo1"
                         "photo2 process"
                         "about-alyssa photo3";
  }
}

@media (min-width: 1200px) {
  .about {
    width: min(100%, 1200px);
    border-radius: 1rem;
    margin-block: 2rem;
    margin-inline: auto;
    font-size: 1.25rem;
    overflow: hidden;
  }
}

.footer {
  flex-wrap: wrap;
  justify-content: center;
  display: flex;
}

.footer p {
  margin-inline: .5rem;
}

.footer a {
  color: var(--neutral-900);
  transition: color .2s;
}

.footer a:hover {
  color: var(--neutral-300);
}

@keyframes gradient-animation {
  100% {
    background-position: 100%;
  }
}

@keyframes shake_y {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-5%);
  }

  80% {
    transform: translateY(1%);
  }

  100% {
    transform: translateY(0);
  }
}

@media (min-width: 650px) {
  .contact-form {
    grid-template-columns: 1fr 1fr;
    justify-items: center;
    gap: 1rem;
    display: grid;
  }

  .contact-btn {
    grid-column: 1 / 3;
  }
}

/*# sourceMappingURL=index.ddbc0cf3.css.map */
